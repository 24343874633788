import './App.css';
import { useEffect, useState, lazy, Suspense } from 'react';

const GalleryModal = lazy(() => import('./components/GalleryModal'));

function App() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isGalleryOpen, setIsGalleryOpen] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [loadedImages, setLoadedImages] = useState<Set<number>>(new Set());

  const galleryImages = [
    '/gallery/image001.jpg',
    '/gallery/image002.jpg',
    '/gallery/image003.jpg',
    '/gallery/image004.jpg',
    '/gallery/image005.jpg',
    '/gallery/image006.jpg',
    '/gallery/image007.jpg',
    '/gallery/image008.jpg',
    '/gallery/image009.jpg',
    '/gallery/image010.jpg',
    '/gallery/image011.jpg',
    '/gallery/image012.jpg',
    '/gallery/image013.jpg',
    '/gallery/image014.jpg',
    '/gallery/image015.jpg',
    '/gallery/image016.jpg',
    '/gallery/image017.jpg',
    '/gallery/image018.jpg',
    '/gallery/image009.jpg',
    '/gallery/image020.jpg',
    '/gallery/image021.jpg',
    '/gallery/image022.jpg',
    '/gallery/image023.jpg',
    '/gallery/image024.jpg',
    '/gallery/image025.jpg',
    '/gallery/image026.jpg',
    '/gallery/image027.jpg',
    '/gallery/image028.jpg',
    '/gallery/image029.jpg',
    '/gallery/image030.jpg',
    '/gallery/image031.jpg',
    '/gallery/image032.jpg',
    '/gallery/image033.jpg',
    '/gallery/image034.jpg',
    '/gallery/image035.jpg',
    '/gallery/image036.jpg',
    '/gallery/image037.jpg',
    '/gallery/image038.jpg',
    '/gallery/image039.jpg',
    '/gallery/image040.jpg',
  ];

  // Preload function for images
  const preloadImage = (index: number) => {
    if (index >= 0 && index < galleryImages.length && !loadedImages.has(index)) {
      const img = new Image();
      img.src = galleryImages[index];
      img.onload = () => {
        setLoadedImages(prev => new Set(prev).add(index));
      };
    }
  };

  // Preload current and adjacent images
  useEffect(() => {
    if (isGalleryOpen) {
      preloadImage(currentImageIndex); // Current image
      preloadImage(currentImageIndex + 1); // Next image
      preloadImage(currentImageIndex - 1); // Previous image
    }
  }, [currentImageIndex, isGalleryOpen]);

  const handleNextImage = () => {
    setCurrentImageIndex((prevIndex) => {
      const nextIndex = prevIndex + 1;
      if (nextIndex >= galleryImages.length) {
        setTimeout(() => {
          setCurrentImageIndex(0);
        }, 500);
        return prevIndex;
      }
      return nextIndex;
    });
  };

  const handlePrevImage = () => {
    setCurrentImageIndex((prevIndex) => {
      if (prevIndex === 0) {
        setTimeout(() => {
          setCurrentImageIndex(galleryImages.length - 1);
        }, 500);
        return prevIndex;
      }
      return prevIndex - 1;
    });
  };

  useEffect(() => {
    const handleKeyPress = (e: KeyboardEvent) => {
      if (!isGalleryOpen) return;
      if (e.key === 'ArrowRight') handleNextImage();
      if (e.key === 'ArrowLeft') handlePrevImage();
      if (e.key === 'Escape') setIsGalleryOpen(false);
    };

    window.addEventListener('keydown', handleKeyPress);
    return () => window.removeEventListener('keydown', handleKeyPress);
  }, [isGalleryOpen]);

  return (
    <div className="min-h-screen">
      <header className="bg-white shadow-lg fixed w-full z-50">
        <div className="container mx-auto px-4 py-6 flex items-center justify-between">
          <h1 className="text-2xl font-bold text-gray-800">Seven-Lines</h1>
          
          {/* Mobile menu button */}
          <button 
            className="md:hidden text-gray-600 hover:text-gray-900"
            onClick={() => setIsMenuOpen(!isMenuOpen)}
          >
            <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              {isMenuOpen ? (
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
              ) : (
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16M4 18h16" />
              )}
            </svg>
          </button>

          {/* Desktop Navigation */}
          <nav className="hidden md:block">
            <ul className="flex space-x-6 items-center">
              <li><a href="#home" className="text-gray-600 hover:text-gray-900">Главная</a></li>
              <li><a href="#projects" className="text-gray-600 hover:text-gray-900">Проекты</a></li>
              <li><a href="#services" className="text-gray-600 hover:text-gray-900">Услуги</a></li>
              <li><a href="#equipment" className="text-gray-600 hover:text-gray-900">Оборудование</a></li>
              <li><a href="#about" className="text-gray-600 hover:text-gray-900">О Нас</a></li>
              <li><a href="#contact" className="text-gray-600 hover:text-gray-900">Контакты</a></li>
              <li>
                <button 
                  onClick={() => setIsGalleryOpen(true)}
                  className="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors"
                >
                  Галерея
                </button>
              </li>
            </ul>
          </nav>
        </div>

        {/* Mobile Navigation */}
        <nav className={`md:hidden ${isMenuOpen ? 'block' : 'hidden'} bg-white border-t`}>
          <ul className="flex flex-col">
            <li><a href="#home" className="block px-4 py-2 text-gray-600 hover:bg-gray-100">Главная</a></li>
            <li><a href="#projects" className="block px-4 py-2 text-gray-600 hover:bg-gray-100">Проекты</a></li>
            <li><a href="#services" className="block px-4 py-2 text-gray-600 hover:bg-gray-100">Услуги</a></li>
            <li><a href="#equipment" className="block px-4 py-2 text-gray-600 hover:bg-gray-100">Оборудование</a></li>
            <li><a href="#about" className="block px-4 py-2 text-gray-600 hover:bg-gray-100">О Нас</a></li>
            <li><a href="#contact" className="block px-4 py-2 text-gray-600 hover:bg-gray-100">Контакты</a></li>
            <li>
              <button 
                onClick={() => setIsGalleryOpen(true)}
                className="mx-4 my-2 px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 w-full text-left transition-colors"
              >
                Галерея
              </button>
            </li>
          </ul>
        </nav>
      </header>

      {/* Add id="home" to your hero section */}
      <div id="home" className="relative h-screen bg-fixed bg-center bg-cover bg-no-repeat" 
           style={{ 
             backgroundImage: `url('/services-networking.jpg')`,
             marginTop: '0px'
           }}>
        <div className="absolute inset-0 bg-black bg-opacity-50">
          <div className="container mx-auto px-4 h-full flex items-center">
            <div className="text-white max-w-2xl">
              <h1 className="text-5xl font-bold mb-4">Seven-Lines</h1>
              <p className="text-xl">Ваше видение, наш опыт - строим инфраструктуру будущего сегодня</p>
              <button 
                className="mt-8 px-6 py-3 bg-blue-600 hover:bg-blue-700 text-white rounded-lg"
                onClick={() => setIsGalleryOpen(true)}
              >
                Галерея
              </button>
            </div>
          </div>
        </div>
      </div>

      <main className="container mx-auto px-4 py-12 max-w-7xl">

        {/* Add id="projects" to projects section */}
        <section id="projects" className="mb-20">
          <h2 className="text-4xl font-bold text-gray-900 mb-12 text-center">Наши Проекты</h2>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8 my-3">
            <div className="p-6 bg-white rounded-lg shadow-lg">
              <h3 className="text-xl font-bold mb-4">КазГАСА</h3>
              <p className="text-gray-600">Архитектурно-строительная академия</p>
            </div>
            <div className="p-6 bg-white rounded-lg shadow-lg">
              <h3 className="text-xl font-bold mb-4">Нархоз</h3>
              <p className="text-gray-600">Университет</p>
            </div>
            <div className="p-6 bg-white rounded-lg shadow-lg">
              <h3 className="text-xl font-bold mb-4">High Tech Academy</h3>
              <p className="text-gray-600">Первая инновационная проектная школа</p>
            </div>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8 my-3">
            <div className="p-6 bg-white rounded-lg shadow-lg">
              <h3 className="text-xl font-bold mb-4">Лемано ПРО (Леруа Мерлен)</h3>
              <p className="text-gray-600">Строительный магазин</p>
            </div>
            <div className="p-6 bg-white rounded-lg shadow-lg">
              <h3 className="text-xl font-bold mb-4">OBI</h3>
              <p className="text-gray-600">Строительный магазин</p>
            </div>
            <div className="p-6 bg-white rounded-lg shadow-lg">
              <h3 className="text-xl font-bold mb-4">Mega Almaty 2</h3>
              <p className="text-gray-600">Торгов развлекательный центр</p>
            </div>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8 my-3">
            <div className="p-6 bg-white rounded-lg shadow-lg">
              <h3 className="text-xl font-bold mb-4">Евразийский Банк Алматы</h3>
              <p className="text-gray-600"></p>
            </div>
            <div className="p-6 bg-white rounded-lg shadow-lg">
              <h3 className="text-xl font-bold mb-4">Сбербанк Алматы</h3>
              <p className="text-gray-600"></p>
            </div>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8 my-3">
            <div className="p-6 bg-white rounded-lg shadow-lg">
              <h3 className="text-xl font-bold mb-4">MOST Hub Алматы</h3>
              <p className="text-gray-600">Первый бизнес-инкубатор в Центральной Азии</p>
            </div>
            <div className="p-6 bg-white rounded-lg shadow-lg">
              <h3 className="text-xl font-bold mb-4">Рош Казахстан </h3>
              <p className="text-gray-600">Компания фармацевтика и диагностика</p>
            </div>
            <div className="p-6 bg-white rounded-lg shadow-lg">
              <h3 className="text-xl font-bold mb-4">Газпром нефть Казахстан</h3>
              <p className="text-gray-600"></p>
            </div>
          </div>
        </section>

        {/* Add id="services" to services section */}
        <section id="services" className="mb-20">
          <h2 className="text-4xl font-bold text-gray-900 mb-8 text-center">Наши Услуги</h2>
          <h3 className="text-2xl font-bold text-gray-900 mb-6 text-center">проектирование, монтаж, установка</h3>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            <div className="p-6 bg-white rounded-lg shadow-lg">
              <h3 className="text-xl font-bold mb-4">Видеонаблюдение (ВН)</h3>
              <p className="text-gray-600"></p>
            </div>
            <div className="p-6 bg-white rounded-lg shadow-lg">
              <h3 className="text-xl font-bold mb-4">Структурирования кабельная система (СКС)</h3>
              <p className="text-gray-600"></p>
            </div>
            <div className="p-6 bg-white rounded-lg shadow-lg">
              <h3 className="text-xl font-bold mb-4">Система контроля и управления доступом (СКУД)</h3>
              <p className="text-gray-600"></p>
            </div>
            <div className="p-6 bg-white rounded-lg shadow-lg">
              <h3 className="text-xl font-bold mb-4">Внутреннее электрооборудования и освещения (ЭОМ)</h3>
              <p className="text-gray-600"></p>
            </div>
            <div className="p-6 bg-white rounded-lg shadow-lg">
              <h3 className="text-xl font-bold mb-4">Автоматизация здания и инженерных систем (BMS)</h3>
              <p className="text-gray-600"></p>
            </div>
            <div className="p-6 bg-white rounded-lg shadow-lg">
              <h3 className="text-xl font-bold mb-4">Система автоматизации жилья (Умный дом)</h3>
              <p className="text-gray-600"></p>
            </div>
          </div>
        </section>

        {/* Add id="equipment" to equipment section */}
        <section id="equipment" className="mb-20">
          <h2 className="text-4xl font-bold text-gray-900 mb-12 text-center">Постановка и монтаж оборудования</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-12">
            <div className="p-6 bg-white rounded-lg shadow-lg">
              <h3 className="text-xl font-bold mb-4">Подбор спецификации оборудования </h3>
              <p className="text-gray-600"></p>
            </div>
            <div className="p-6 bg-white rounded-lg shadow-lg">
              <h3 className="text-xl font-bold mb-4">Регистрация проекта и возможность получить доп. скидку от производителя оборудования</h3>
              <p className="text-gray-600"></p>
            </div>
          </div>
          <h2 className="text-4xl font-bold text-gray-900 my-12 text-center">Аудит и тестирования существующей инфраструктуры</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-12">
            <div className="p-6 bg-white rounded-lg shadow-lg">
              <h3 className="text-xl font-bold mb-4">Тестирование электрических линий</h3>
              <p className="text-gray-600"></p>
            </div>
            <div className="p-6 bg-white rounded-lg shadow-lg">
              <h3 className="text-xl font-bold mb-4">Тестирование слаботочных линий UTP</h3>
              <p className="text-gray-600"></p>
            </div>
          </div>
          <h2 className="text-4xl font-bold text-gray-900 my-12 text-center">Техническая поддержка</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-12">
            <div className="p-6 bg-white rounded-lg shadow-lg">
              <h3 className="text-xl font-bold mb-4">Круглосуточная техническая поддержка и сервисное обслуживание</h3>
              <p className="text-gray-600"></p>
            </div>
          </div>
        </section>

        {/* Why Choose Us Section */}
        <section id="about" className="mb-20">
          <h2 className="text-4xl font-bold text-gray-900 mb-8 text-center">Почему Выбирают Нас</h2>
          <h3 className="text-3xl font-bold text-gray-900 mb-8 text-center">Опыт и компетенции</h3>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-12">
            <div className="space-y-4">
              <h3 className="text-2xl font-bold text-gray-800">10+ лет на рынке</h3>
              <p className="text-gray-600">Успешные проекты для бизнеса, промышленности, государственных объектов.</p>
            </div>
            <div className="space-y-4">
              <h3 className="text-2xl font-bold text-gray-800">Квалифицированные специалисты </h3>
              <p className="text-gray-600">Инженеры с профильным образованием и сертификацией.</p>
            </div>
            <div className="space-y-4">
              <h3 className="text-2xl font-bold text-gray-800">Реализуем проекты “под ключ”</h3>
              <p className="text-gray-600">От концепции до ввода в эксплуатацию.</p>
            </div>
          </div>
          <h3 className="text-3xl font-bold text-gray-900 my-8 mt-14 text-center">Качество и технологии</h3>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-12">
            <div className="space-y-4">
              <h3 className="text-2xl font-bold text-gray-800"></h3>
              <p className="text-gray-600">Работаем только с проверенным оборудованием ведущих производителей.</p>
            </div>
            <div className="space-y-4">
              <h3 className="text-2xl font-bold text-gray-800"></h3>
              <p className="text-gray-600">Используем современные инженерные решения для надежности и эффективности.</p>
            </div>
            <div className="space-y-4">
              <h3 className="text-2xl font-bold text-gray-800"></h3>
              <p className="text-gray-600">Соблюдаем все нормативные требования (ГОСТ, СНиП, ПУЭ, ISO).</p>
            </div>
          </div>
          <h3 className="text-3xl font-bold text-gray-900 my-8 mt-14 text-center">Индивидуальный подход</h3>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-12">
            <div className="space-y-4">
              <h3 className="text-2xl font-bold text-gray-800"></h3>
              <p className="text-gray-600">Анализируем задачи заказчика и подбираем оптимальные решения по бюджету и срокам.</p>
            </div>
            <div className="space-y-4">
              <h3 className="text-2xl font-bold text-gray-800"></h3>
              <p className="text-gray-600">Гибкость в работе — находим лучший вариант для каждого проекта.</p>
            </div>
            <div className="space-y-4">
              <h3 className="text-2xl font-bold text-gray-800"></h3>
              <p className="text-gray-600">Предоставляем техническую поддержку и гарантийное обслуживание.</p>
            </div>
          </div>
          <h3 className="text-3xl font-bold text-gray-900 my-8 mt-14 text-center">Надежность и безопасность</h3>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-12">
            <div className="space-y-4">
              <h3 className="text-2xl font-bold text-gray-800"></h3>
              <p className="text-gray-600">Прозрачные условия сотрудничества — фиксированные сроки и цены.</p>
            </div>
            <div className="space-y-4">
              <h3 className="text-2xl font-bold text-gray-800"></h3>
              <p className="text-gray-600">Полное соответствие требованиям безопасности на всех этапах работы.</p>
            </div>
            <div className="space-y-4">
              <h3 className="text-2xl font-bold text-gray-800"></h3>
              <p className="text-gray-600">Гарантия на оборудование и монтаж — уверенность в качестве.</p>
            </div>
          </div>
        </section>

        {/* Contact Section */}
        <section id="contact" className="mb-20 bg-gray-50 p-8 rounded-lg">
          {/* <h2 className="text-4xl font-bold text-gray-900 mb-12 text-center">Get in Touch</h2> */}
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-12">
            {/* Contact Information */}
            <div className="space-y-8">
              <div>
                <h3 className="text-2xl font-bold text-gray-800 mb-4">Свяжитесь с Нами</h3>
                <div className="space-y-4">
                  <div className="flex items-start space-x-4">
                    <svg className="w-6 h-6 text-blue-600 mt-1" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z" />
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 11a3 3 0 11-6 0 3 3 0 016 0z" />
                    </svg>
                    <div>
                      <h4 className="font-semibold text-gray-800">Адрес</h4>
                      <p className="text-gray-600">юр. РК, г. Алматы , индекс A10B2X2 Мкр-н. Аксай 4, дом 83 кв. 139.</p>
                    </div>
                  </div>
                  <div className="flex items-start space-x-4">
                    <svg className="w-6 h-6 text-blue-600 mt-1" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z" />
                    </svg>
                    <div>
                      <h4 className="font-semibold text-gray-800">Телефон</h4>
                      <p className="text-gray-600">+7 (777) 227 49 02</p>
                    </div>
                  </div>
                  <div className="flex items-start space-x-4">
                    <svg className="w-6 h-6 text-blue-600 mt-1" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" />
                    </svg>
                    <div>
                      <h4 className="font-semibold text-gray-800">Электронная Почта</h4>
                      <p className="text-gray-600">info@seven-lines.kz</p>
                    </div>
                  </div>
                  <div className="flex items-start space-x-4">
                    <svg className="w-6 h-6 text-blue-600 mt-1" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z" />
                    </svg>
                    <div>
                      <h4 className="font-semibold text-gray-800">Часы Работы</h4>
                      <p className="text-gray-600">Понедельник - Пятница: 9:00 - 18:00<br />Суббота: 10:00 - 14:00</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>



      {/* Lazy loaded Gallery Modal */}
      <Suspense fallback={<div>Loading...</div>}>
        {isGalleryOpen && (
          <GalleryModal
            isOpen={isGalleryOpen}
            onClose={() => setIsGalleryOpen(false)}
            images={galleryImages}
            currentImageIndex={currentImageIndex}
            onPrevImage={handlePrevImage}
            onNextImage={handleNextImage}
            loadedImages={loadedImages}
          />
        )}
      </Suspense>
    </div>
  );
}

export default App;
